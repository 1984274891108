// 1. 导入模块
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'

// 2. 将 Vuex 挂载到 Vue 上
Vue.use(Vuex)

// 3. 创建 Vuex 实例
const store = new Vuex.Store({
    state: {
    },
    mutations: {
		
	},
	plugins: [createPersistedState({
        storage: window.sessionStorage // 使用 sessionStorage 而不是 localStorage
    })],
    modules: {
        modules,
    }
})


// 4. 向外暴露出去 store
export default store
