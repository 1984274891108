import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: () => import('../views/index.vue'),
    redirect: 'index',
    children: [{
        path: '/',
        component: () => import('@/views/homeView.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/about',
        component: () => import('@/views/aboutView.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: '/theoreticalQuestion',
        component: () => import('../views/theoreticalQuestionView.vue'),
        meta: {
          title: '理论题库'
        }
      },
      {
        path: '/answer',
        component: () => import('../views/answer.vue'),
        meta: {
          title: '专项刷题'
        }
      },
      {
        path: '/daTum',
        component: () => import('../views/daTum.vue'),
        meta: {
          title: '资料'
        }
      },
      {
        path: '/videoView',
        component: () => import('../views/videoView.vue'),
        meta: {
          title: '视频课程'
        }
      },
      {
        path: '/videoDirectory',
        component: () => import('../views/videoDirectory.vue'),
        meta: {
          title: '课程目录'
        }
      },
      {
        path: '/answerResult',
        component: () => import('../views/answerResult.vue'),
        meta: {
          title: '练习结果'
        }
      },
      {
        path: '/details',
        component: () => import('../views/details.vue'),
        meta: {
          title: '详情页'
        }
      },
      {
        path: '/notice',
        component: () => import('../views/notice.vue'),
        meta: {
          title: '列表页'
        }
      },
      {
        path: '/computerTest',
        component: () => import('../views/computerTest.vue'),
        meta: {
          title: '理论机考系统'
        }
      },
      {
        path: '/answerSuiji',
        component: () => import('../views/answerSuiji.vue'),
        meta: {
          title: '理论机考系统'
        }
      },
      
    ]
  },
  {
    path: '/login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/verify',
    component: () => import('../views/verify.vue'),
    meta: {
      title: '机考验证'
    }
  },
  {
    path: '/theoryTest',
    component: () => import('../views/theoryTest.vue'),
    meta: {
      title: '理论机考系统'
    }
  },
  {
    path: '/agreement',
    component: () => import('../views/agreement.vue'),
    meta: {
      title: '考试协议'
    }
  },
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({

  routes
})
router.beforeEach((to, from, next) => {
  // 设置页面标题  
  document.title = to.meta.title + '-消控百事通-消防实施操作员';
  next();
});
export default router