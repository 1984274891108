import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 全局引入图片验证码组件
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
// 导入vuex
import store from './store/index.js'
// Vue.prototype.axios = axios
export default axios;
Vue.use(ElementUI);
// Vue.use(axios);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
